import React, { useEffect, useState } from "react"
// import "../assets/scss/main.scss"
// import 'aos/dist/aos.css' 
import AOS from 'aos'
import Footer from "./Footer"
import Navbar from "./Navbar"
import { BannerContextWrapper } from "../lib/context"
import { Helmet } from "react-helmet"

// export default class Template extends React.Component {
//     constructor( props ) {
//       super( props )
//       this.state = {
//         loading: 'is-loading',
//       }
//     }

//     async componentDidMount () {
//       AOS.init( {
//         mirror: true,
//         once: true,
//         duration: 400,
//       } )

//       this.timeoutId = setTimeout( () => {
//           this.setState( { loading: '' } )
//       }, 100 )

//     }

//     componentWillUnmount () {
//       if ( this.timeoutId ) {
//           clearTimeout( this.timeoutId )
//       }
//     }

//     render() {
//         const { children } = this.props

//         return (
//             <div className={`body ${this.state.loading}`}>
//                 <Navbar />
//                 {children}
//                 <Footer />
//             </div>
//         )
//     }
// }

const Layout = ( { location, children } ) => {
  const [ loading, setLoading ] = useState( 'is-loading' )
  let timeoutId

  useEffect( () => {

    AOS.init( {
      mirror: true,
      once: true,
      duration: 400,
    } )

    timeoutId = setTimeout( () => {
      setLoading( '' )
    }, 100 )

    return () => timeoutId && clearTimeout( timeoutId ) 

  }, [] )

  const siteTitle = 'Blackheath and Charlton Baptist Church'
  const metaData = [
    {
      name: `description`,
      content: 'BCBChurch',
    },
    {
      name: `keywords`,
      content: [
        "Christmas Service",
        "Nativity Play",
        "New Year's Eve Service",
        "Christmas Mini Fair",
        "Nativity Play",
        "Carols by Candlelight",
        "Vertical Youth Club",
        "VYC",
        "Blackheath and Charlton Baptist Church Vertical Youth Club",
        "BCBCVYC",
        "Community Youth Club",
        "Youth Club in Charlton",
        "Youth Club in Cherry Orchard",
        "Youth Club in Shooters Hill",
        "Youth Club in Blackheath",
        "Southeast London Youth Club",
        "London Borough of Greenwich Youth Club",
        "Youth Club in London",
        "Greenwich Youth for Christ",
        "London City Mission",
        "Youth Club",
        "Youth group",
        "Youth diversity",
        "Youth Games",
        "Youth Interactive Games",
        "Wii",
        "Youth Table Tennis",
        "Youth Snooker",
        "Youth football",
        "Boardgames",
        "Drawing",
        "Painting",
        "Creativity",
        "Gospel music",
        "Term Time",
        "Weekday youth club",
        "Tuesday youth club",
        "6pm - 7:30pm",
        "Ages 10-16",
        "Snacks",
        "Youth Club Pizza night",
        "Youth Club Movie night",
        "Youth Club BBQ",
        "Young Christians",
        "Blackheath and Charlton Baptist Church Youth Ministry",
        "BCBC Youth Ministry",
        "Youth Ministry in Charlton",
        "Youth Ministry in Blackheath",
        "Youth Ministry in Shooters Hill",
        "Southeast London Youth Ministry",
        "London Borough of Greenwich Youth Ministry",
        "Youth Ministry in London",
        "Youth Ministry",
        "Youth Baptism",
        "Sunday Youth Ministry",
        "Family Focus",
        "Bible based youth ministry",
        "Youth Icebreaker activities",
        "Youth Icebreaker games",
        "Youth Sports Day",
        "Vertical Youth Club Summer activities",
        "BCBC Youth worship",
        "Youth worship",
        "BCBC Youth sermons",
        "Youth sermons",
        "BCBC Youth teachings",
        "Youth teachings",
        "Community Easter Egg Hunt and tea party",
        "BCBC Community Easter Egg Hunt and tea party",
        "BCBC Nativity Play",
        "Youth and Children",
        "BCBC Easter",
        "Spirit Filled Church",
        "Bible Based Church",
        "Multi-cultural Church",
        "Church with Creche",
        "Christingles",
        "Christmas Carols",
        "Women's Ministry",
        "Come As You Are",
        "Life Groups",
        "Knitting",
        "Craft",
        "Men's Ministry",
        "Blackheath Church",
        "Charlton Church",
        "Eltham Church",
        "Sidcup Church",
        "Lewisham Church",
        "Abbeywoods Church",
        "Woolwich Church",
        "Family Church",
        "Church of Jesus",
        "Place of love",
        "Heart of service",
        "Living Church",
        "Dynamic Church",
        "Looking to Jesus",
      ].toString(),
    },
  ]

  return (
      <div className={`body ${loading}`} id='body'>
        <Helmet title={siteTitle} meta={metaData} />
        <Navbar />
            {children}
        <Footer />
      </div>
  )
}

export default Layout
