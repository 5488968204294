import React, { useState } from "react"
import Helmet from "react-helmet"

import Layout from '../components/layout'
import AniLink from "gatsby-plugin-transition-link/AniLink"


import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import map from '../assets/images/map.png'
import Swal from "sweetalert2"
import { navigate } from 'gatsby'
import { loginUser } from "../lib/API"



const MediaPage = () => {
    const siteTitle = "Blackheath and Charlton Baptist Church"
    const [ loginData, setLoginData ] = useState( {
        identifier: "",
        password: ""
    } )

    const login = async ( e ) => {
        e.preventDefault()
        console.log( loginData )
        try {
            const res = await loginUser( loginData )
            console.log( res )

            if( res && res.error )
                throw res.error

            const { user, jwt } = res
            

            Swal.fire( "Success", "Logging in", "success" ).then( () => navigate( "/my-account" ) )
        }catch( e ) {
            Swal.fire( "Error", "Incorrect usename and/or password", "error" )
        }
    }


    return (
            
        <>
            <Helmet title={siteTitle} />
            {/* <Header icon={headerIcon} images={headerImages} headerTitle={headerTitle} tint={0.3} headerButtons={headerButtons} /> */}

            
          

            <section id="one" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Login</h2>
                        </header>
                    </div>

                    <div className="col-12">
                        <form className="login-form" method="POST" onSubmit={login}>
                            <label>Email</label>
                            <input className="form-input" required type="email" name="email" placeholder="joebloggs@example.com" onChange={( e ) => setLoginData( { ...loginData, identifier: e.target.value } )}/>

                            <label>Password</label>
                            <input className="form-input" required type="password" name="password" onChange={( e ) => setLoginData( { ...loginData, password: e.target.value } )} />

                            <br />
                            <ul className="actions uniform">
                                <li><AniLink fade duration={0.4} to="/register" className="button">Register</AniLink></li>
                                <li><input type="submit" className="button special" value="Log In" /></li>
                            </ul>
                            
                        </form>
                    </div>

                </div>
            </section>

            {/* <section id="two" className="main style1">
                <div className="grid-wrapper">

                    <div className="col-12">
                        <span className="image fit"><img src={pic01} alt="" /></span>
                    </div>
                </div>
            </section> */}


            {/* <section id="two" className="main style2">
                <div className="grid-wrapper">
                    <div className="col-6">
                        <ul className="major-icons">
                            <li><span className="icon style1 major fa-code"></span></li>
                            <li><span className="icon style2 major fa-bolt"></span></li>
                            <li><span className="icon style3 major fa-camera-retro"></span></li>
                            <li><span className="icon style4 major fa-cog"></span></li>
                            <li><span className="icon style5 major fa-desktop"></span></li>
                            <li><span className="icon style6 major fa-calendar"></span></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <header className="major">
                            <h2>Lorem ipsum dolor adipiscing<br />
                            amet dolor consequat</h2>
                        </header>
                        <p>Adipiscing a commodo ante nunc accumsan interdum mi ante adipiscing. A nunc lobortis non nisl amet vis volutpat aclacus nascetur ac non. Lorem curae eu ante amet sapien in tempus ac. Adipiscing id accumsan adipiscing ipsum.</p>
                        <p>Blandit faucibus proin. Ac aliquam integer adipiscing enim non praesent vis commodo nunc phasellus cubilia ac risus accumsan. Accumsan blandit. Lobortis phasellus non lobortis dit varius mi varius accumsan lobortis. Blandit ante aliquam lacinia lorem lobortis semper morbi col faucibus vitae integer placerat accumsan orci eu mi odio tempus adipiscing adipiscing adipiscing curae consequat feugiat etiam dolore.</p>
                        <p>Adipiscing a commodo ante nunc accumsan interdum mi ante adipiscing. A nunc lobortis non nisl amet vis volutpat aclacus nascetur ac non. Lorem curae eu ante amet sapien in tempus ac. Adipiscing id accumsan adipiscing ipsum.</p>
                    </div>
                </div>
            </section> */}

            {/* <section id="one" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Adipiscing amet consequat</h2>
                        </header>
                        <p>Ante nunc accumsan et aclacus nascetur ac ante amet sapien sed.</p>
                    </div>

                    <div className="col-4">
                        <span className="image fit"><img src={pic02} alt="" /></span>
                        <h3>Magna feugiat lorem</h3>
                        <p>Adipiscing a commodo ante nunc magna lorem et interdum mi ante nunc lobortis non amet vis sed volutpat et nascetur.</p>
                        <ul className="actions">
                            <li><a href="#" className="button">More</a></li>
                        </ul>
                    </div>
                    <div className="col-4">
                        <span className="image fit"><img src={pic03} alt="" /></span>
                        <h3>Magna feugiat lorem</h3>
                        <p>Adipiscing a commodo ante nunc magna lorem et interdum mi ante nunc lobortis non amet vis sed volutpat et nascetur.</p>
                        <ul className="actions">
                            <li><a href="#" className="button">More</a></li>
                        </ul>
                    </div>
                    <div className="col-4">
                        <span className="image fit"><img src={pic04} alt="" /></span>
                        <h3>Magna feugiat lorem</h3>
                        <p>Adipiscing a commodo ante nunc magna lorem et interdum mi ante nunc lobortis non amet vis sed volutpat et nascetur.</p>
                        <ul className="actions">
                            <li><a href="#" className="button">More</a></li>
                        </ul>
                    </div>

                </div>
            </section>

            <section id="four" className="main style2 special">
                <div className="container">
                    <header className="major">
                        <h2>Ipsum feugiat consequat?</h2>
                    </header>
                    <p>Sed lacus nascetur ac ante amet sapien.</p>
                    <ul className="actions uniform">
                        <li><a href="#" className="button special">Sign Up</a></li>
                        <li><a href="#" className="button">Learn More</a></li>
                    </ul>
                </div>
            </section> */}
        </>
    )
}

export default MediaPage